import {
  ArrowDropDown,
  ArrowDropUp,
  Search,
  Visibility,
} from "@material-ui/icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import Pagination from "./Pagination";
import SortIcon from "../../../assets/sort.png";

const BaseTable = ({
  columns,
  data = [],
  paginationRequired = true,
  searchRequired = true,
  sortingRequired = true,
  heading = "",
  searchPlaceholder = "",
  onSelectedRowsChange = () => {},
}) => {
  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filter,
      rowSelection,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    getRowId: (row) => row.id,
  });

  useEffect(() => {
    if (onSelectedRowsChange) {
      const selectedRows = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);
      onSelectedRowsChange(selectedRows);
    }
  }, [rowSelection]);

  return (
    <div className="container-fluid">
      {searchRequired && (
        <div className="row mb-4">
          <div className="col-md-6">
            <h4 className="mb-md-0">{heading || ""}</h4>
          </div>
          <div className="col-md-6">
            <div
              className="input-group"
              style={{ maxWidth: "300px", marginLeft: "auto" }}
            >
              <input
                type="text"
                className="form-control"
                placeholder={searchPlaceholder || "Search"}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              <span className="input-group-text">
                <Search />
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="table-responsive">
        <table className="table table-hover align-middle">
          <thead className="table-light">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="py-3 align-middle"
                    style={{
                      cursor: sortingRequired ? "pointer" : "default",
                      whiteSpace: "nowrap",
                      verticalAlign: "middle",
                    }}
                    onClick={
                      sortingRequired && header.column.getToggleSortingHandler()
                    }
                  >
                    <div className="d-flex align-items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {sortingRequired && (
                        <span className="ms-2">
                          {header.column.getIsSorted() === "asc" ? (
                            <ArrowDropUp />
                          ) : header.column.getIsSorted() === "desc" ? (
                            <ArrowDropDown />
                          ) : (
                            <img
                              alt="sort"
                              src={SortIcon}
                              width={10}
                              height={10}
                              className="opacity-25"
                            />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="align-middle py-1"
                      style={{ verticalAlign: "middle" }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center py-4">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {paginationRequired && (
        <div className="mt-3">
          <Pagination table={table} />
        </div>
      )}
    </div>
  );
};

export default BaseTable;
