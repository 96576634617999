import axios from "axios";

const tourUrl = `${process.env.REACT_APP_APIURL}/tournament`;
const authUrl = `${process.env.REACT_APP_APIURL}/auth`;
const leagueUrl = `${process.env.REACT_APP_APIURL}/league`;
const teamUrl = `${process.env.REACT_APP_APIURL}/team`;
const externalCronUrl = `${process.env.REACT_APP_APIURL}/cron`;
const userStatsUrl = `${process.env.REACT_APP_APIURL}/userStats`;

const jsonconfig = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

// <!-- ===== Auth ===== -->
const login = async (data) => {
  try {
    const response = await axios.post(
      `${authUrl}/admin/login`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const userLogout = async () => {
  try {
    const response = await axios.get(`${authUrl}/admin/logout`, jsonconfig);
    return response;
  } catch (err) {
    return err.response;
  }
};

// <!-- ===== Tournament ===== -->
const createTournament = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/create-tournament`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchLatestTournament = async () => {
  try {
    const response = await axios.get(
      `${leagueUrl}/get-latest-tournament`,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchTournament = async (data) => {
  try {
    const response = await axios.post(
      `${leagueUrl}/fetch-tournaments`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const createMultiSourceTour = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/multi-source-tournament`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchSourceTourPlayers = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/fetchtournamentplayers`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const getLeagueCategories = async () => {
  try {
    const response = await axios.get(
      `${leagueUrl}/league-categories`,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchRegionalTourList = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/regional-tournaments`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchRoundOptions = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/fetch-rounds`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchPlayers = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/fetch-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchTournamentList = async () => {
  try {
    const response = await axios.get(
      `${tourUrl}/admin/tournament-list`,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const tournamentExists = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/existing-tournament`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// <!-- ===== Leagues ===== -->
const fetchLeagueCategories = async () => {
  try {
    const response = await axios.get(
      `${leagueUrl}/league-categories`,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchLeagues = async (data) => {
  try {
    const response = await axios.post(
      `${leagueUrl}/fetch-leagues`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchTournaments = async (data) => {
  try {
    const response = await axios.post(
      `${leagueUrl}/fetch-tournaments`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchTournamentPlayers = async (data) => {
  try {
    const response = await axios.post(
      `${leagueUrl}/fetch-tournament-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const updatePlayer = async (data) => {
  try {
    const response = await axios.post(
      `${teamUrl}/update-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// external cron functions
const externalCall = async () => {
  try {
    const response = await axios.get(
      `${externalCronUrl}/start-main-job`,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// player update automation routes
const playerUpdateAutomation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/automate/final-results`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// update all players
const updatePlayers = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/automate/update-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const getUserStats = async () => {
  try {
    const response = await axios.get(`${userStatsUrl}/admin/stats`, jsonconfig);
    return response;
  } catch (err) {
    return err.response;
  }
};

const getSpecificUserStats = async (data) => {
  try {
    const response = await axios.post(
      `${userStatsUrl}/admin/get-user-stats`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const getUserSpecificLeaderboards = async (data) => {
  try {
    const response = await axios.post(
      `${userStatsUrl}/admin/get-user-tour-leaderboards`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchTournamentLeaderboards = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/tournament-leaderboard`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchTeamPlayersOfUser = async (data) => {
  try {
    const response = await axios.post(
      `${teamUrl}/admin/fetch-teamplayers`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const changeTournamentStatus = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/change-tour-status`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchExistingPlayers = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/fetch-existing-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const deletePlayerById = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/delete-player`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const bulkDeletePlayers = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/bulk-delete-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const addPlayersInExistingTournament = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/add-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

const bulkUpdatePlayers = async (data) => {
  try {
    const response = await axios.post(
      `${tourUrl}/admin/bulk-update-players`,
      data,
      jsonconfig
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export {
  createTournament,
  login,
  userLogout,
  getLeagueCategories,
  fetchRegionalTourList,
  fetchRoundOptions,
  fetchPlayers,
  tournamentExists,
  fetchTournamentList,
  fetchLeagueCategories,
  fetchLeagues,
  fetchTournaments,
  fetchTournamentPlayers,
  updatePlayer,
  createMultiSourceTour,
  fetchSourceTourPlayers,
  externalCall,
  playerUpdateAutomation,
  updatePlayers,
  fetchLatestTournament,
  fetchTournament,
  getUserStats,
  getSpecificUserStats,
  getUserSpecificLeaderboards,
  fetchTournamentLeaderboards,
  fetchTeamPlayersOfUser,
  changeTournamentStatus,
  fetchExistingPlayers,
  deletePlayerById,
  bulkDeletePlayers,
  addPlayersInExistingTournament,
  bulkUpdatePlayers
};
