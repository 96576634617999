import React, { useEffect, useState } from "react";
import logoimages from "../../images/logo_1.png";
import { Link } from "react-router-dom";
import Logo1 from "../../images/Screenshot_1.png";
import {
  addPlayersInExistingTournament,
  bulkDeletePlayers,
  bulkUpdatePlayers,
  deletePlayerById,
  fetchExistingPlayers,
  fetchRegionalTourList,
  fetchRoundOptions,
  fetchSourceTourPlayers,
  getLeagueCategories,
  userLogout,
} from "../../api";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../app/features/userSlice";
import { showToast } from "../toast";
import { useHistory, useLocation } from "react-router-dom";
import BaseTable from "./data-table/BaseTable";
import EditableTable from "./data-table/EditableTable";
import Select from "react-select";

const EditPlayers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const tourData = location.state && location.state.tourData;
  const [loading, setLoading] = useState(false);

  const [existingPlayers, setExistingPlayers] = useState([]);
  const [newPlayers, setNewPlayers] = useState([]);
  const [errors, setErrors] = useState(null);
  const [initialData, setInitialData] = useState([]);

  const [regionOptions, setRegionOptions] = useState([]);
  const [tournamentOptions, setTournamentOptions] = useState([]);
  const [roundOptions, setRoundOptions] = useState([]);
  const [playersToAdd, setPlayersToAdd] = useState([]);
  const [selectedNewPlayers, setSelectedNewPlayers] = useState([]);

  const handleLogout = async () => {
    const response = await userLogout();
    if (response.status === 200) {
      dispatch(logoutUser());
      showToast("Successfully logged out", 200);
      history.push({
        pathname: "/login",
      });
    }
  };

  // <!-- Players From API Code Starts here -->

  // Form Data
  const [sourceTournaments, setSourceTournaments] = useState({
    region: "",
    round: "",
    tournamentmain: "",
    NumberOfPlayers: 0,
  });

  const handleSelectPlayer = (data) => {
    setPlayersToAdd((prevPlayers) => [...prevPlayers, data]);
  };

  const newPlayerListCols = React.useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <input
            type="checkbox"
            style={{ display: "block" }}
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            style={{ display: "block" }}
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        enableSorting: false,
      },
      {
        header: "Player Name",
        accessorKey: "name",
        cell: ({ row }) => (
          <div className="text-left font-medium">{row.original.name}</div>
        ),
      },
      // {
      //   header: "Player ID",
      //   accessorKey: "player_id",
      //   cell: ({ row }) => (
      //     <div className="text-left text-sm text-gray-500">
      //       {row.original.player_id}
      //     </div>
      //   ),
      // },
      {
        header: "Price",
        accessorKey: "price",
        cell: ({ row }) => {
          return (
            <input
              key={row.original.player_id}
              type="number"
              className="form-control text-center"
              value={row.original.price === 0 ? "" : row.original.price ?? ""}
              onChange={(e) => {
                const inputValue = e.target.value;
                const newPrice =
                  inputValue === "" ? null : parseFloat(inputValue);

                setSelectedNewPlayers((prev) =>
                  prev.map((player) =>
                    player.player_id === row.original.player_id
                      ? { ...player, price: newPrice }
                      : player
                  )
                );

                setNewPlayers((prev) =>
                  prev.map((player) =>
                    player.player_id === row.original.player_id
                      ? { ...player, price: newPrice }
                      : player
                  )
                );
              }}
            />
          );
        },
      },
      {
        header: "Round Score",
        accessorKey: "roundscore",
        cell: ({ row }) => (
          <div className="text-right font-medium">
            {row.original.roundscore}
          </div>
        ),
      },
    ],
    []
  );

  const handleUpdate = async (updatedPlayers) => {
    if (updatedPlayers.length === 0) {
      showToast("Please Select Players", 400);
      return;
    }
    try {
      setLoading(true);
      const response = await bulkUpdatePlayers({
        updatedPlayers,
        initialData,
      });

      if (response.status === 200) {
        showToast("Successfully updated player(s)", 200);

        let result;

        if (initialData.isCumulative) {
          result = await fetchExistingPlayers({
            tour_id: initialData.tour_id,
            window_id: tourData.round1,
          });
        } else {
          result = await fetchExistingPlayers({
            tour_id: initialData.tour_id,
            window_id: tourData.round2,
          });
        }

        if (result.status === 200) {
          setExistingPlayers(result?.data?.data || []);
        } else {
          setExistingPlayers([]);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleUpdate = async (updatedPlayers) => {
  //   if (updatedPlayers.length === 0) {
  //     showToast("Please Select Players", 400);
  //     return;
  //   }

  //   try {
  //     setLoading(true);
  //     const response = await bulkUpdatePlayers({
  //       updatedPlayers,
  //       initialData,
  //     });

  //     if (response.status === 200) {
  //       showToast("Successfully updated player(s)", 200);
  //       let result;

  //       if (initialData.isCumulative) {
  //         // For cumulative tournaments, fetch round1 data first
  //         result = await fetchExistingPlayers({
  //           tour_id: initialData.tour_id,
  //           window_id: tourData.round1,
  //         });

  //         // Only fetch round2 data if round2 exists and is not empty
  //         if (tourData.round2 && tourData.round2.trim() !== "") {
  //           const round2Result = await fetchExistingPlayers({
  //             tour_id: initialData.tour_id,
  //             window_id: tourData.round2,
  //           });

  //           // If round2 fetch is successful, use its data
  //           if (round2Result.status === 200) {
  //             result = round2Result;
  //           }
  //         }
  //       } else {
  //         // For non-cumulative tournaments, only fetch round2
  //         result = await fetchExistingPlayers({
  //           tour_id: initialData.tour_id,
  //           window_id: tourData.round2,
  //         });
  //       }

  //       // Update players list based on API result
  //       if (result?.status === 200) {
  //         setExistingPlayers(result?.data?.data || []);
  //       } else {
  //         setExistingPlayers([]);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error);
  //     showToast("Failed to update players", 500);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleFormChange = async (key, val) => {
    if (key === "tournamentmain") {
      await setSourceTournaments((prev) => ({
        ...prev,
        tournamentmain: val.value,
      }));
      return;
    } else {
      setSourceTournaments((prev) => ({
        ...prev,
        [key]: val,
      }));
    }
  };

  const haandleSubmit = async () => {
    try {
      const tempErrors = {};
      if (sourceTournaments.region.trim().length === 0) {
        tempErrors["region"] = "Please Select Region";
      }

      if (sourceTournaments.region.trim().length === 0) {
        tempErrors["tournament"] = "Please Select Tournament";
      }

      if (sourceTournaments.round.trim().length === 0) {
        tempErrors["round"] = "Please Select Round";
      }

      setErrors(tempErrors);

      if (Object.keys(tempErrors.length === 0)) {
        const modifiedTournament = {
          region: sourceTournaments.region,
          round: sourceTournaments.round,
          tournamentmain: sourceTournaments.tournament,
          NumberOfPlayers: sourceTournaments.NumberOfPlayers,
        };

        const dataToSend = [modifiedTournament];

        setLoading(true);

        const response = await fetchSourceTourPlayers(dataToSend);
        if (response.status === 200) {
          setNewPlayers(
            response.data?.data?.[sourceTournaments.region]?.[
              sourceTournaments.round
            ]
          );
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  // get region options
  useEffect(() => {
    async function getRegionOptions() {
      try {
        const response = await getLeagueCategories();
        if (response.status === 200) {
          setRegionOptions(response.data.data);
        }

        if (response.status === 400) {
          setErrors((prev) => ({
            ...prev,
            region: "No regions found",
          }));
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    }

    getRegionOptions();
  }, []);

  // get tournament options
  useEffect(() => {
    async function fetchRegionalTournamentList() {
      try {
        setTournamentOptions([]);

        if (sourceTournaments.region) {
          const response = await fetchRegionalTourList({
            region: sourceTournaments.region,
          });

          if (response.status === 200) {
            if (response?.data?.length > 0) {
              setTournamentOptions(response.data);
            }
          }

          if (
            response.status === 400 &&
            response.data.additionalData.noTourFound
          ) {
            setErrors((prev) => ({
              ...prev,
              tournament: response.data.message,
            }));
          } else {
            setErrors((prev) => ({
              ...prev,
              tournament: "",
            }));
          }
        }
      } catch (error) {
        console.log("Error", error);
      }
    }
    fetchRegionalTournamentList();
  }, [sourceTournaments.region]);

  // <!-- Players From API End Here -->

  // fetch rounds
  useEffect(() => {
    async function fetchRounds() {
      try {
        setRoundOptions([]);
        if (sourceTournaments.tournamentmain) {
          const response = await fetchRoundOptions({
            region: sourceTournaments.region,
            tournamentmain: sourceTournaments.tournamentmain,
          });

          if (response.status === 200) {
            if (response?.data?.length > 0) {
              setRoundOptions(response.data);
            }
          }

          if (
            response.status === 400 &&
            response.data.additionalData.noRoundsFound
          ) {
            setErrors((prev) => ({
              ...prev,
              round: response.data.message,
            }));
          } else {
            setErrors((prev) => ({
              ...prev,
              round: "",
            }));
          }
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    }
    fetchRounds();
  }, [sourceTournaments.tournamentmain]);

  // async function fetchPlayers() {
  //   if (!tourData) {
  //     console.log("No tournament data provided");
  //     return;
  //   }

  //   try {
  //     setLoading(true);

  //     const {
  //       iscumulative: isCumulative,
  //       round2,
  //       round3,
  //       id: tour_id,
  //       round1: window_id,
  //       tour_name,
  //     } = tourData;

  //     // Initialize the data to send
  //     let dataToSend = {
  //       tour_id,
  //       isCumulative,
  //       rounds: [],
  //       window_id,
  //       tour_name,
  //     };

  //     console.log("tour data: ", tourData);
  //     console.log("dataToSend: ", dataToSend);

  //     // Check if the tournament is cumulative
  //     if (isCumulative) {
  //       // Add round2 if it exists

  //       if (round2) {
  //         dataToSend.rounds.push({ round: round2 });
  //       } else {
  //         dataToSend.rounds.push({ round: window_id });
  //       }

  //       // Add round3 if both round2 and round3 exist
  //       if (round2 && round3) {
  //         dataToSend.rounds.push({ round: round3 });
  //       }
  //     } else {
  //       // If not cumulative, only check for round2
  //       if (round2) {
  //         dataToSend.rounds.push({ round: round2 });
  //       }
  //     }

  //     setInitialData(dataToSend);
  //     let response;

  //     if (dataToSend.isCumulative) {
  //       console.log("If Data to send: ", dataToSend, tourData);
  //       response = await fetchExistingPlayers({
  //         tour_id: dataToSend.tour_id,
  //         window_id: tourData.round1,
  //       });
  //     }

  //     response = await fetchExistingPlayers({
  //       tour_id: dataToSend.tour_id,
  //       window_id: tourData.round2,
  //     });

  //     // Fetch existing players (replace with your actual API call)

  //     if (response.status === 200) {
  //       setExistingPlayers(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     showToast(500, "Something went wrong");
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  async function fetchPlayers() {
    if (!tourData) {
      console.log("No tournament data provided");
      return;
    }

    try {
      setLoading(true);

      const {
        iscumulative: isCumulative,
        round2,
        round3,
        id: tour_id,
        round1: window_id,
        tour_name,
      } = tourData;

      // Initialize the data to send
      let dataToSend = {
        tour_id,
        isCumulative,
        rounds: [],
        window_id,
        tour_name,
      };


      // Handle rounds based on cumulative status
      if (isCumulative) {
        // Always add round1 first
        dataToSend.rounds.push({ round: window_id });

        // Only add round2 if it exists and is not empty
        if (round2 && round2.trim() !== "") {
          dataToSend.rounds.push({ round: round2 });

          // Only add round3 if round2 exists and round3 exists
          if (round3 && round3.trim() !== "") {
            dataToSend.rounds.push({ round: round3 });
          }
        }
      } else {
        // For non-cumulative, only add round2 if it exists and is not empty
        if (round2 && round2.trim() !== "") {
          dataToSend.rounds.push({ round: round2 });
        }
      }

      setInitialData(dataToSend);
      let response;

      if (isCumulative) {
        // For cumulative tournaments, first fetch round1 data
        response = await fetchExistingPlayers({
          tour_id: dataToSend.tour_id,
          window_id: window_id, // Use round1/window_id here
        });
      }

      // Only fetch round2 data if round2 exists and is not empty
      if (round2 && round2.trim() !== "") {
        response = await fetchExistingPlayers({
          tour_id: dataToSend.tour_id,
          window_id: round2,
        });
      }

      if (response?.status === 200) {
        setExistingPlayers(response.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
      showToast(500, "Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  const handleBulkDelete = async (selectedIds) => {
    try {
      setLoading(true);
      const response = await bulkDeletePlayers({ selectedIds });

      if (response.status === 200) {
        // Fetch updated player list after successful deletion
        const updatedPlayerList = await fetchExistingPlayers({
          tour_id: initialData.tour_id,
          window_id: initialData.window_id,
        });

        if (updatedPlayerList.status === 200) {
          setExistingPlayers(updatedPlayerList?.data?.data || []);
          showToast("Players successfully deleted", 200);
        }

        if (updatedPlayerList.status >= 400 && updatedPlayerList.status < 500) {
          setExistingPlayers([]);
        }
      }
    } catch (error) {
      console.log("Error while bulk deleting players: ", error);
      showToast(500, "Something went wrong while deleting players");
    } finally {
      setLoading(false);
    }
  };

  // Player which already exists in database

  // fetch existing players
  useEffect(() => {
    fetchPlayers();
  }, [tourData]);

  const handlePriceChange = (id, newPrice) => {
    setExistingPlayers(
      existingPlayers.map((player) =>
        player.id === id ? { ...player, price: newPrice } : player
      )
    );
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await deletePlayerById({ id });

      if (response.status === 200) {
        let result;

        if (initialData.isCumulative) {
          result = await fetchExistingPlayers({
            tour_id: initialData.tour_id,
            window_id: tourData.round1,
          });
        } else {
          result = await fetchExistingPlayers({
            tour_id: initialData.tour_id,
            window_id: tourData.round2,
          });
        }

        if (result.status === 200) {
          setExistingPlayers(result?.data?.data || []);
        } else {
          setExistingPlayers([]);
        }
      }
    } catch (error) {
      console.log("Error while deleting player: ", error);
      showToast(500, "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectionChange = (selectedIds) => {
    // console.log("Selected players:", selectedIds);
    // Handle selected players as needed
  };

  const [activeTab, setActiveTab] = useState("active");

  // tabs logic
  const renderContent = () => {
    switch (activeTab) {
      case "active":
        return (
          <div className="col-12 gap-3">
            <div>
              <div
                className="d-flex flex-column gap-3"
                style={{ width: "100%" }}
              >
                <div className="text-center">
                  <Select
                    defaultValue={sourceTournaments.region || ""}
                    onChange={(e) => handleFormChange("region", e.label)}
                    options={regionOptions}
                    placeholder="Select Region"
                  />
                  {errors && errors.region ? (
                    <span className="text-danger">{errors.region}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-center">
                  <Select
                    defaultValue={sourceTournaments.tournament}
                    onChange={(e) => handleFormChange("tournamentmain", e)}
                    options={tournamentOptions}
                    placeholder="Select Tournament"
                  />
                  {errors && errors.tournament ? (
                    <span className="text-danger">{errors.tournament}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-center">
                  <Select
                    defaultValue={sourceTournaments.round}
                    onChange={(e) => handleFormChange("round", e.value)}
                    options={roundOptions}
                    placeholder="Select Round"
                  />

                  {errors && errors.round ? (
                    <span className="text-danger">{errors.round}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className=" text-center">
                  <div className="mt-4 d-flex gap-2 justify-content-center align-items-center">
                    <span htmlFor="player">Number of Players</span>
                    <input
                      type="number"
                      id="player"
                      className="form-control text-center"
                      value={sourceTournaments.NumberOfPlayers || 0}
                      onChange={(e) =>
                        handleFormChange("NumberOfPlayers", e.target.value)
                      }
                      style={{ width: "100px", height: "40px" }}
                    />
                  </div>
                  {errors && errors.NumberOfPlayers ? (
                    <span className="text-danger">
                      {errors.NumberOfPlayers}
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  <button
                    className="selecttourbutton my-2"
                    onClick={haandleSubmit}
                  >
                    Fetch Players
                  </button>
                </div>
              </div>
            </div>

            {newPlayers && newPlayers.length ? (
              <div>
                <div className="sticky-top bg-light py-3 mb-3 shadow-sm text-end">
                  {selectedNewPlayers.length > 0 && (
                    <span
                      className="ms-3 text-muted"
                      style={{ marginRight: "10px" }}
                    >
                      {selectedNewPlayers.length} players selected
                    </span>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={handleAddPlayers}
                    disabled={selectedNewPlayers.length === 0}
                  >
                    Add Selected Players
                  </button>
                </div>
                <BaseTable
                  data={newPlayers && newPlayers.length ? newPlayers : []}
                  columns={newPlayerListCols}
                  onSelectedRowsChange={setSelectedNewPlayers}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );

      case "add":
        return (
          <div className=" col-12 d-flex align-items-center">
            <div className="card-body" style={{ width: "100%" }}>
              <EditableTable
                players={existingPlayers}
                onPriceChange={handlePriceChange}
                onDelete={handleDelete}
                onSelectionChange={handleSelectionChange}
                onBulkDelete={handleBulkDelete}
                onUpdate={handleUpdate}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleAddPlayers = async () => {
    try {
      setLoading(true);
      const playersToAdd = selectedNewPlayers.map((player) => ({
        player_id: player.player_id,
        name: player.name,
        price: player.price,
        roundscore: player.roundscore,
      }));

      // Your API call here
      const response = await addPlayersInExistingTournament({
        initialData,
        playersToAdd,
      });

      if (response.status === 200) {
        showToast("Players added successfully", 200);
        let result;


        if (initialData.isCumulative) {
          result = await fetchExistingPlayers({
            tour_id: initialData.tour_id,
            window_id: tourData.round1,
          });
        } else {
          result = await fetchExistingPlayers({
            tour_id: initialData.tour_id,
            window_id: tourData.round2,
          });
        }

        if (result.status === 200) {
          setExistingPlayers(result?.data?.data || []);
        } else {
          setExistingPlayers([]);
        }
      }

      if (response.status >= 400 && response.status <= 500) {
        showToast(500, "Something went wrong");
      }
    } catch (error) {
      console.error("Error adding players:", error);
      showToast("Failed to add players", 500);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="sectionlanding">
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
            <img
              src={logoimages}
              alt="logo"
              style={{ width: 100, height: 100 }}
            />
          </div>
        ) : (
          <div className="contenorlanding">
            <div className="contentrow col-12">
              <div className="nevcolumn col-2">
                <div className="nevlogo">
                  <img className="photoimagelanding" src={Logo1} alt="logo" />
                </div>
                <div className="nevlistst">
                  <li className="listnevbars">
                    <Link
                      to="/players-list"
                      className="text-decoration-none text-black"
                    >
                      Player List
                    </Link>
                  </li>

                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          style={{ fontSize: "16px", pointerEvents: "none" }}
                          className="accordion-button listnevbars p-0 shadow-none bg-white text-black"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Tournament
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion show collapse px-2"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/tournament-list"
                            className="text-decoration-none text-black px-2"
                          >
                            <span style={{ fontSize: "14px" }}>List</span>
                          </Link>
                        </div>
                        <div className="accordion-body p-0 d-flex flex-row justify-content-start align-items-center rounded-circle gap-2 px-2">
                          <Link
                            to="/create-tournament"
                            className="text-decoration-none text-black text-sm px-2"
                          >
                            <span style={{ fontSize: "14px" }}>Create</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <li className="listnevbars">
                    <Link
                      to="/user-stats"
                      className="text-decoration-none text-black"
                    >
                      Users
                    </Link>
                  </li>
                </div>
              </div>

              <div
                className="col-10 screensection"
                style={{ height: "auto", background: "#F8F8F8" }}
              >
                <div className="landingRow col-12 mb-4">
                  <div className="nevbarbutton p-0">
                    <button
                      className="nevbarlogbutton m-0"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>

                {/* <div className="col-12 gap-3">
                  <div>
                    <div
                      className="d-flex flex-column gap-3"
                      style={{ width: "100%" }}
                    >
                      <div className="text-center">
                        <Select
                          defaultValue={sourceTournaments.region || ""}
                          onChange={(e) => handleFormChange("region", e.label)}
                          options={regionOptions}
                          placeholder="Select Region"
                        />
                        {errors && errors.region ? (
                          <span className="text-danger">{errors.region}</span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="text-center">
                        <Select
                          defaultValue={sourceTournaments.tournament}
                          onChange={(e) =>
                            handleFormChange("tournamentmain", e)
                          }
                          options={tournamentOptions}
                          placeholder="Select Tournament"
                        />
                        {errors && errors.tournament ? (
                          <span className="text-danger">
                            {errors.tournament}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="text-center">
                        <Select
                          defaultValue={sourceTournaments.round}
                          onChange={(e) => handleFormChange("round", e.value)}
                          options={roundOptions}
                          placeholder="Select Round"
                        />

                        {errors && errors.round ? (
                          <span className="text-danger">{errors.round}</span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className=" text-center">
                        <div className="mt-4 d-flex gap-2 justify-content-center align-items-center">
                          <span htmlFor="player">Number of Players</span>
                          <input
                            type="number"
                            id="player"
                            className="form-control text-center"
                            value={sourceTournaments.NumberOfPlayers || 0}
                            onChange={(e) =>
                              handleFormChange(
                                "NumberOfPlayers",
                                e.target.value
                              )
                            }
                            style={{ width: "100px", height: "40px" }}
                          />
                        </div>
                        {errors && errors.NumberOfPlayers ? (
                          <span className="text-danger">
                            {errors.NumberOfPlayers}
                          </span>
                        ) : (
                          ""
                        )}
                        <br />
                        <button
                          className="selecttourbutton my-2"
                          onClick={haandleSubmit}
                        >
                          Fetch Players
                        </button>
                      </div>
                    </div>
                  </div>

                  {newPlayers && newPlayers.length ? (
                    <div>
                      <div className="mt-3 text-end align-items-center pb-4">
                        {selectedNewPlayers.length > 0 && (
                          <span
                            className="ms-3 text-muted"
                            style={{ marginRight: "10px" }}
                          >
                            {selectedNewPlayers.length} players selected
                          </span>
                        )}
                        <button
                          className="btn btn-primary"
                          onClick={handleAddPlayers}
                          disabled={selectedNewPlayers.length === 0}
                        >
                          Add Selected Players
                        </button>
                      </div>
                      <BaseTable
                        data={newPlayers && newPlayers.length ? newPlayers : []}
                        columns={newPlayerListCols}
                        onSelectedRowsChange={setSelectedNewPlayers}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className=" col-12 d-flex align-items-center">
                  <div className="card-body" style={{ width: "100%" }}>
                    <EditableTable
                      players={existingPlayers}
                      onPriceChange={handlePriceChange}
                      onDelete={handleDelete}
                      onSelectionChange={handleSelectionChange}
                      onBulkDelete={handleBulkDelete}
                      onUpdate={handleUpdate}
                    />
                  </div>
                </div> */}

                <div className="landing-box col-12 d-flex flex-column gap-2 pt-3">
                  {/* Tabs */}
                  <ul className="nav nav-pills  d-flex flex-row justify-content-center align-items-center">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          activeTab === "add" ? "active-btn" : ""
                        }`}
                        aria-current="page"
                        onClick={() => setActiveTab("add")}
                      >
                        Edit/Delete Players
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          activeTab === "active" ? "active-btn" : ""
                        }`}
                        onClick={() => setActiveTab("active")}
                      >
                        Add Players
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content mt-3">{renderContent()}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditPlayers;
