/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { bulkDeletePlayers } from "../../../api";
import { Search } from "@material-ui/icons";

const EditableTable = ({
  players,
  onPriceChange,
  onDelete,
  onBulkDelete,
  onSelectionChange,
  onUpdate,
}) => {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState("");
  const [modifiedPrices, setModifiedPrices] = useState(new Map());
  const [updatedPlayers, setUpdatedPlayers] = useState(new Map());

  const handlePriceChange = (id, newPrice) => {
    const player = players.find((p) => p.id === id);
    setModifiedPrices((prev) => new Map(prev.set(id, newPrice)));
    onPriceChange?.(id, newPrice);
  };

  const handleUpdate = () => {
    // Get only selected players
    const selectedPlayers = players.filter((player) =>
      selectedRows.has(player.id)
    );

    // Update their prices if modified
    const updatedSelectedPlayers = selectedPlayers.map((player) => {
      const newPrice = modifiedPrices.get(player.id);
      if (newPrice !== undefined && newPrice !== "") {
        return {
          ...player,
          price: parseFloat(newPrice),
        };
      }
      return player;
    });

    // Call the onUpdate prop with the updated selected players
    onUpdate?.(updatedSelectedPlayers);
  };

  // Bulk Delete
  const handleDelete = async () => {
    try {
      const selectedIds = Array.from(selectedRows);

      if (selectedIds.length) {
        await onBulkDelete?.(selectedIds);
        // Clear selection after successful deletion
        setSelectedRows(new Set());
      }
    } catch (error) {
      console.error("Error in bulk delete: ", error);
    }
  };

  const toggleRow = (id) => {
    const newSelected = new Set(selectedRows);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedRows(newSelected);
    onSelectionChange?.(Array.from(newSelected));
  };

  const toggleAllRows = () => {
    if (selectedRows.size === players.length) {
      setSelectedRows(new Set());
      onSelectionChange?.([]);
    } else {
      const allIds = players.map((p) => p.id);
      setSelectedRows(new Set(allIds));
      onSelectionChange?.(allIds);
    }
  };

  const filteredPlayers = players.filter((player) =>
    player.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // return (
  //   <div className="container-fluid">
  //     <div className="mb-3">
  //       <input
  //         type="text"
  //         placeholder="Search"
  //         className="form-control"
  //         value={searchQuery}
  //         onChange={(e) => setSearchQuery(e.target.value)}
  //       />
  //     </div>

  //     {/* <div
  //       className="text-end px-4 py-1"
  //       style={{
  //         position: "sticky",
  //         top: "0px",
  //         right: "10px",
  //         zIndex: 1000,
  //         boxShadow: "10px gray",
  //         background: "rgb(248 248 248)",
  //       }}
  //     >
  //       <button
  //         className="btn btn-primary"
  //         onClick={handleUpdate}
  //         disabled={modifiedPrices.size === 0}
  //       >
  //         Update
  //       </button>

  //       <button
  //         className="btn btn-danger"
  //         onClick={handleDelete}
  //         disabled={selectedRows.size === 0}
  //       >
  //         Delete
  //       </button>
  //     </div> */}

  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-between",
  //         position: "sticky",
  //         top: "0px",
  //         right: "10px",
  //         zIndex: 1000,
  //         boxShadow: "10px gray",
  //         background: "rgb(248 248 248)",
  //         paddingBlock: "5px",
  //       }}
  //     >
  //       <div className="text-start">
  //         <div>
  //           <span style={{ color: "#C2C2C2" }}>
  //             Please select a player to update their price or delete them
  //           </span>
  //         </div>
  //         <div className="fw-bold">
  //           Selected Players:
  //           {(selectedRows && selectedRows.size) || 0}
  //         </div>
  //       </div>
  //       <div className="d-flex justify-center gap-3">
  //         <button
  //           className="btn btn-primary"
  //           onClick={handleUpdate}
  //           disabled={modifiedPrices.size === 0 || selectedRows.size === 0}
  //         >
  //           Update
  //         </button>

  //         <button
  //           className="btn btn-danger"
  //           onClick={handleDelete}
  //           disabled={selectedRows.size === 0}
  //         >
  //           Delete
  //         </button>
  //       </div>
  //     </div>

  //     <div className="table-responsive">
  //       <table className="table table-striped table-bordered">
  //         <thead>
  //           <tr>
  //             <th className="align-middle">
  //               <div className="d-flex justify-content-center">
  //                 <input
  //                   style={{ display: "block" }}
  //                   type="checkbox"
  //                   checked={selectedRows.size === players.length}
  //                   onChange={toggleAllRows}
  //                 />
  //               </div>
  //             </th>
  //             <th className="align-middle">Player Name</th>
  //             <th className="align-middle">Price</th>
  //             <th className="align-middle">Actions</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {filteredPlayers.map((player, index) => (
  //             <tr key={player.id}>
  //               <td className="align-middle">
  //                 <div className="d-flex justify-content-center">
  //                   <input
  //                     style={{ display: "block" }}
  //                     type="checkbox"
  //                     checked={selectedRows.has(player.id)}
  //                     onChange={() => toggleRow(player.id)}
  //                   />
  //                 </div>
  //               </td>
  //               <td className="align-middle">{player.name}</td>
  //               <td className="align-middle">
  //                 <input
  //                   type="number"
  //                   value={
  //                     modifiedPrices.has(player.id)
  //                       ? modifiedPrices.get(player.id)
  //                       : player.price
  //                   }
  //                   onChange={(e) =>
  //                     handlePriceChange(player.id, e.target.value)
  //                   }
  //                   className="form-control form-control-sm text-center"
  //                   style={{ width: "80px" }}
  //                   min="0"
  //                 />
  //               </td>
  //               <td className="align-middle">
  //                 <button
  //                   className="btn btn-danger"
  //                   onClick={() => onDelete?.(player.id)}
  //                 >
  //                   DELETE
  //                 </button>
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );

  return (
    <div className="container-fluid py-4">
      {/* Search Box */}
      {/* <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <input
            type="text"
            placeholder="Search"
            className="form-control"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div> */}

      <div>
        <div className="col-md-6">
          <h4 className="mb-md-0"></h4>
        </div>
        <div
          className="input-group"
          style={{ maxWidth: "300px", marginLeft: "auto" }}
        >
          <input
            type="text"
            className="form-control"
            placeholder={"Search"}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="input-group-text">
            <Search />
          </span>
        </div>
      </div>

      {/* Header Section with Buttons */}
      <div className="row sticky-top bg-light py-3 mb-3 shadow-sm">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="text-muted mb-1">
                Please select a player to update their price or delete them
              </span>
              <p className="fw-bold mb-0">
                Selected Players: {(selectedRows && selectedRows.size) || 0}
              </p>
            </div>
            <div className="d-flex gap-3">
              <button
                className="btn btn-primary px-4"
                onClick={handleUpdate}
                disabled={modifiedPrices.size === 0 || selectedRows.size === 0}
              >
                UPDATE
              </button>
              <button
                className="btn btn-danger px-4"
                onClick={handleDelete}
                disabled={selectedRows.size === 0}
              >
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="table-responsive">
        <table className="table table-striped table-hover align-middle">
          <thead className="table-light">
            <tr>
              <th className="text-center" style={{ width: "50px" }}>
                <input
                  type="checkbox"
                  style={{ display: "block" }}
                  checked={selectedRows.size === players.length}
                  onChange={toggleAllRows}
                />
              </th>
              <th>Player Name</th>
              <th style={{ width: "150px" }}>Price</th>
              <th style={{ width: "120px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPlayers.map((player, index) => (
              <tr key={player.id}>
                <td className="text-center">
                  <input
                    type="checkbox"
                    style={{ display: "block" }}
                    checked={selectedRows.has(player.id)}
                    onChange={() => toggleRow(player.id)}
                  />
                </td>
                <td>{player.name}</td>
                <td>
                  <input
                    type="number"
                    value={
                      modifiedPrices.has(player.id)
                        ? modifiedPrices.get(player.id)
                        : player.price
                    }
                    onChange={(e) =>
                      handlePriceChange(player.id, e.target.value)
                    }
                    className="form-control form-control-sm text-center"
                    style={{ width: "100px" }}
                    min="0"
                  />
                </td>
                <td>
                  <button
                    className="btn btn-danger btn-sm w-100"
                    onClick={() => onDelete?.(player.id)}
                  >
                    DELETE
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EditableTable;
